<template>
  <v-col>
    <v-card>
      <v-card-title class="justify-center"><span class="text-center" style="word-break: normal">{{header}}</span></v-card-title>
      <v-card-text class="text-center">
        <h1 class="text-4xl font-weight-semibold">{{count}}</h1>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: 'Total',
  props: ['header', 'count'],
}
</script>

<style scoped>

</style>
