<template>
  <div>
    <v-card>
      <v-card-text>
        <DateTimePicker
          @getDateFrom="dateFrom = $event"
          @getDateTo="dateTo = $event"
        ></DateTimePicker>
        <br>
        <v-row
          v-if="!$checkRole('ADVERTISERS') && !$checkRole('ADVERTISERS_USERS') && !$checkRole('PROVIDERS')"
        >
          <v-col
            cols="6"
            xl="6"
            md="6"
            sm="12"
          >
            <v-autocomplete
              v-model="advertiserId"
              :items="advertisers"
              item-text="name"
              item-value="id"
              :label="$t('Advertiser')"
              :disabled="loadingFirst"
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="1"
            xl="1"
            md="1"
            sm="1"
          >
            <v-progress-circular
              v-if="loadingFirst"
              class="mt-5"
              indeterminate
              color="primary"
              :width="2"
              :size="20"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <br>
        <v-row>
          <v-col
            cols="6"
            xl="6"
            md="6"
            sm="12"
          >
            <v-autocomplete
              ref="camp"
              v-model="campaignId"
              :items="campaigns"
              item-text="name"
              item-value="id"
              :label="$t('CampaignFilter')"
              :disabled="loadingCampaign"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="1"
            xl="1"
            md="1"
            sm="1"
          >
            <v-progress-circular
              v-if="loadingCampaign"
              class="mt-5"
              indeterminate
              color="primary"
              :width="2"
              :size="20"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <br>
        <v-btn
          color="primary"
          @click="getDataFromDB"
        >
          {{ $t('BtnSearch') }}
        </v-btn>
      </v-card-text>
    </v-card>

    <br>
    <v-row>
      <v-col class="col-3">
        <v-row>
          <TotalWidget
            :header="$t('GeoElements.HeaderTotalPeriod')"
            :count="countPeriod"
          ></TotalWidget>
        </v-row>
        <v-row>
          <TotalWidget
            :header="$t('GeoElements.HeaderTotalAll')"
            :count="countAllPeriod"
          ></TotalWidget>
        </v-row>
      </v-col>
      <v-col class="col-9">
        <v-card>
          <div
            ref="chartMap"
            style="height: 450px"
          />
          <v-card-text class="mt-2">
            <v-row>
              <v-col class="col-3">
                <v-badge
                  color="#00cfdd"
                  dot
                  inline
                  class="align-self-center label-dot"
                ></v-badge> {{ $t('GeoElements.clicksFrom1To300') }}
              </v-col>
              <v-col class="col-3">
                <v-badge
                  color="#5a8dee"
                  dot
                  inline
                  class="align-self-center label-dot"
                ></v-badge> {{ $t('GeoElements.clicksFrom300To500') }}
              </v-col>
              <v-col class="col-3">
                <v-badge
                  color="warning"
                  dot
                  inline
                  class="align-self-center label-dot"
                ></v-badge> {{ $t('GeoElements.clicksFrom500To1000') }}
              </v-col>
              <v-col class="col-3">
                <v-badge
                  color="#ff5b5c"
                  dot
                  inline
                  class="align-self-center label-dot"
                ></v-badge> {{ $t('GeoElements.clicksFromGreater1000') }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <br>
    <div>
      <v-data-table
        :headers="headers"
        :items="dataFromDb"
        item-key="name"
        class="elevation-1"
        :search="search"
        :loading="loading"
        :locale="$i18n.locale"
        sort-by="clicks"
        :sort-desc="true"
      >
        <template v-slot:top>
          <div class="row">
            <div class="col-8"></div>
            <div class="col-4">
              <v-text-field
                v-model="search"
                :label="$t('LblSearch')"
                class="mx-4"
              ></v-text-field>
            </div>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-snackbar
      v-model="showMessage"
      :timeout="timeout"
      color="error"
    >
      {{ messageText }}
    </v-snackbar>
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4maps from '@amcharts/amcharts4/maps'
import am4geodataWorldLow from '@amcharts/amcharts4-geodata/worldLow'
import { i18n } from '@/plugins/i18n'
import Vue from 'vue'
import TotalWidget from './Widgets/TotalWidget.vue'
import DateTimePicker from '../Components/DateTimePicker.vue'

// import am4themes_animated from "@amcharts/amcharts4/themes/animated"

// am4core.useTheme(am4themes_animated)

export default {
  components: {
    DateTimePicker,
    TotalWidget,
  },
  data() {
    return {
      advertisers: [],
      advertiserId: null,
      campaigns: [],
      loadingCampaign: false,
      loadingFirst: false,
      showMessage: false,
      messageText: '',
      campaignId: Number(this.$route.params.campaign_id),
      search: '',
      dataFromDb: [],
      resultForMapFrom1To300: [],
      resultForMapFrom300To500: [],
      resultForMapFrom500To1000: [],
      resultForMapGreaterThan1000: [],
      dateFrom: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )
        .toISOString()
        .substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      loading: false,
      countPeriod: '',
      countAllPeriod: '',
      isDialogVisible: false,
      disabled: false,
    }
  },
  computed: {
    headers() {
      return [
        { text: this.$t('GeoElements.City'), value: i18n.locale === 'ru' ? 'cityRu' : 'cityEn' },
        { text: this.$t('GeoElements.Clicks'), value: 'clicks' },
      ]
    },
  },
  watch: {
    '$i18n.locale': function () {
      this.getDataFromDB()
    },
    advertiserId() {
      this.getCampaigns(false)
    },
  },
  mounted() {
    this.firstLoad()
  },
  methods: {
    firstLoad() {
      this.getAdvertisers().then(() => {
        this.getCampaigns(true).then(() => {
          this.getDataFromDB()
        })
      })
    },

    getAdvertisers() {
      this.loadingFirst = true

      return this.$http
        .get(`${this.$apiBaseURL}/publisher/filter/adv-users`, { params: { userId: this.$getUserId() } })
        .then(response => {
          this.advertisers = response.data
          if (this.firstLoad === true) {
            this.advertiserId = null
          }

          this.loadingFirst = false
        })
    },

    getCampaigns(isFirstLoad) {
      this.loadingCampaign = true

      return this.$http
        .get(`${this.$apiBaseURL}/campaigns/filter/campaigns`, {
          params: { userId: this.$getUserId(), advertiserId: this.advertiserId },
        })
        .then(response => {
          this.campaigns = response.data !== null ? response.data : []
          if (isFirstLoad) {
            if (!this.campaignId) {
              if (this.campaigns.length > 0) {
                this.campaignId = this.campaigns[0].id
              } else {
                this.campaignId = null
              }
            }
          } else if (this.campaigns.length > 0) {
            this.campaignId = this.campaigns[0].id
          } else {
            this.campaignId = null
          }

          this.loadingCampaign = false
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          this.showMessage = true
          this.messageText = error
        })
    },

    getDataFromDB() {
      this.disabled = true
      this.dataFromDb = []
      this.resultForMapFrom1To300 = []
      this.resultForMapFrom300To500 = []
      this.resultForMapFrom500To1000 = []
      this.resultForMapGreaterThan1000 = []
      if (!this.campaignId) {
        this.messageText = this.$t('EmptyCampaignId')
        this.showMessage = true

        return
      }

      this.loading = true
      this.countPeriod = 0
      this.countAllPeriod = 0

      const dateStart = this.dateFrom != null ? `${this.dateFrom}` : '1970-01-01'
      // eslint-disable-next-line no-multi-spaces, operator-linebreak
      const dateFinish = this.dateTo != null ? `${this.dateTo}` : `${new Date().toISOString().substr(0, 10)}`

      this.$http
        .get(`${Vue.prototype.$apiBaseURL}/geo`, {
          params: {
            dateFrom: dateStart,
            dateTo: dateFinish,
            userId: this.$getUserId(),
            campaignIds: this.campaignId,
          },
        })
        .then(response => {
          this.dataFromDb = response.data !== null ? response.data : []

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.dataFromDb.length; i++) {
            if (this.dataFromDb[i].clicks != null && this.dataFromDb[i].clicks > 0) {
              this.countPeriod += this.dataFromDb[i].clicks
              if (this.dataFromDb[i].latitude != null && this.dataFromDb[i].longitude != null) {
                if (this.dataFromDb[i].clicks <= 300) {
                  this.resultForMapFrom1To300.push({
                    latitude: this.dataFromDb[i].latitude,
                    longitude: this.dataFromDb[i].longitude,
                    cityRu: this.dataFromDb[i].cityRu,
                    cityEng: this.dataFromDb[i].cityEn,
                    clicks: this.dataFromDb[i].clicks,
                  })
                } else if (this.dataFromDb[i].clicks > 300 && this.dataFromDb[i].clicks <= 500) {
                  this.resultForMapFrom300To500.push({
                    latitude: this.dataFromDb[i].latitude,
                    longitude: this.dataFromDb[i].longitude,
                    cityRu: this.dataFromDb[i].cityRu,
                    cityEng: this.dataFromDb[i].cityEn,
                    clicks: this.dataFromDb[i].clicks,
                  })
                } else if (this.dataFromDb[i].clicks > 500 && this.dataFromDb[i].clicks <= 1000) {
                  this.resultForMapFrom500To1000.push({
                    latitude: this.dataFromDb[i].latitude,
                    longitude: this.dataFromDb[i].longitude,
                    cityRu: this.dataFromDb[i].cityRu,
                    cityEng: this.dataFromDb[i].cityEn,
                    clicks: this.dataFromDb[i].clicks,
                  })
                } else {
                  this.resultForMapGreaterThan1000.push({
                    latitude: this.dataFromDb[i].latitude,
                    longitude: this.dataFromDb[i].longitude,
                    cityRu: this.dataFromDb[i].cityRu,
                    cityEng: this.dataFromDb[i].cityEn,
                    clicks: this.dataFromDb[i].clicks,
                  })
                }
              }
            }
          }

          this.loading = false
          this.chartMap(this.$refs.chartMap)
          this.disabled = false
        })
        .catch(error => {
          console.log(error)
          this.chartMap(this.$refs.chartMap)
          this.loading = false
          this.disabled = false
        })

      this.$http
        .get(`${Vue.prototype.$apiBaseURL}/geo`, {
          params: {
            dateFrom: null,
            dateTo: null,
            userId: this.$getUserId(),
            campaignIds: this.campaignId,
          },
        })
        .then(response => {
          const { data } = response
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < data.length; i++) {
            this.countAllPeriod += data[i].clicks != null ? data[i].clicks : 0
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    chartMap(element) {
      const chart = am4core.create(element, am4maps.MapChart)

      chart.geodata = am4geodataWorldLow

      chart.projection = new am4maps.projections.Miller()

      const zoomControl = new am4maps.ZoomControl()
      chart.zoomControl = zoomControl
      zoomControl.slider.height = 100

      // Add button home (кнопка домой для возврата масштаба)
      const button = chart.chartContainer.createChild(am4core.Button)
      button.padding(5, 5, 5, 5)
      button.align = 'right'
      button.marginRight = 15
      button.events.on('hit', () => {
        chart.goHome()
      })
      button.icon = new am4core.Sprite()
      button.icon.path = 'M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8'

      // Create map polygon series
      const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries())

      // Make map load polygon (like country names) data from GeoJSON
      polygonSeries.useGeodata = true
      polygonSeries.nonScalingStroke = true
      polygonSeries.strokeWidth = 0.5
      polygonSeries.calculateVisualCenter = true

      // Configure series
      const polygonTemplate = polygonSeries.mapPolygons.template
      polygonTemplate.tooltipText = i18n.locale === 'ru' ? '{cityRu}' : '{cityEng}'
      polygonTemplate.fill = am4core.color('#b288fe')
      polygonTemplate.stroke = am4core.color('#deccfe')

      polygonSeries.exclude = ['AQ']

      // Маленькая карта в углу
      chart.smallMap = new am4maps.SmallMap()
      chart.smallMap.series.push(polygonSeries)

      // Выделение страни при наведени
      const hs = polygonTemplate.states.create('hover')
      hs.properties.fill = am4core.color('#9155fd')

      // Масштабирование области карты по клику
      polygonTemplate.events.on('hit', ev => {
        ev.target.series.chart.zoomToMapObject(ev.target)
      })

      // Create image series
      const imageSeries1 = chart.series.push(new am4maps.MapImageSeries())

      // // Add data for the three cities
      imageSeries1.data = this.resultForMapFrom1To300
      imageSeries1.dataFields.value = 'clicks'

      // Create a circle image in image series template, so it gets replicated to all new images
      const imageSeriesTemplate1 = imageSeries1.mapImages.template

      const circle1 = imageSeriesTemplate1.createChild(am4core.Circle)
      circle1.fill = am4core.color('#00cfdd')
      circle1.stroke = am4core.color('#00cfdd')
      circle1.fillOpacity = 0.8
      circle1.tooltipText = i18n.locale === 'ru' ? '{cityRu} : {clicks} переходов' : '{cityEng} : {clicks} clicks'

      // Set property fields
      imageSeriesTemplate1.propertyFields.latitude = 'latitude'
      imageSeriesTemplate1.propertyFields.longitude = 'longitude'

      // Обьем круга в зависимости от количества кликов
      imageSeriesTemplate1.nonScaling = true
      imageSeries1.heatRules.push({
        target: circle1,
        property: 'radius',
        min: 5,
        max: 15,
        dataField: 'value',
      })

      // Create image series
      const imageSeries2 = chart.series.push(new am4maps.MapImageSeries())

      // Create a circle image in image series template, so it gets replicated to all new images
      const imageSeriesTemplate2 = imageSeries2.mapImages.template
      const circle2 = imageSeriesTemplate2.createChild(am4core.Circle)
      circle2.fill = am4core.color('#5A8DEE')
      circle2.stroke = am4core.color('#5A8DEE')
      circle2.fillOpacity = 0.7
      circle2.tooltipText = i18n.locale === 'ru' ? '{cityRu} : {clicks} переходов' : '{cityEng} : {clicks} clicks'

      // Set property fields
      imageSeriesTemplate2.propertyFields.latitude = 'latitude'
      imageSeriesTemplate2.propertyFields.longitude = 'longitude'

      // Add data for the three cities
      imageSeries2.data = this.resultForMapFrom300To500
      imageSeries2.dataFields.value = 'clicks'

      // Обьем круга в зависимости от количества кликов
      imageSeriesTemplate2.nonScaling = true
      imageSeries2.heatRules.push({
        target: circle2,
        property: 'radius',
        min: 15,
        max: 20,
        dataField: 'value',
      })

      // Create image series
      const imageSeries3 = chart.series.push(new am4maps.MapImageSeries())

      // Create a circle image in image series template, so it gets replicated to all new images
      const imageSeriesTemplate3 = imageSeries3.mapImages.template
      const circle3 = imageSeriesTemplate3.createChild(am4core.Circle)
      circle3.fill = am4core.color('#FDAC41')
      circle3.stroke = am4core.color('#FDAC41')
      circle3.fillOpacity = 0.7
      circle3.tooltipText = i18n.locale === 'ru' ? '{cityRu} : {clicks} переходов' : '{cityEng} : {clicks} clicks'

      // Set property fields
      imageSeriesTemplate3.propertyFields.latitude = 'latitude'
      imageSeriesTemplate3.propertyFields.longitude = 'longitude'

      // Add data for the three cities
      imageSeries3.data = this.resultForMapFrom500To1000
      imageSeries3.dataFields.value = 'clicks'

      // Обьем круга в зависимости от количества кликов
      imageSeriesTemplate3.nonScaling = true
      imageSeries3.heatRules.push({
        target: circle3,
        property: 'radius',
        min: 20,
        max: 25,
        dataField: 'value',
      })

      // Create image series
      const imageSeries4 = chart.series.push(new am4maps.MapImageSeries())

      // Create a circle image in image series template so it gets replicated to all new images
      const imageSeriesTemplate4 = imageSeries4.mapImages.template
      const circle4 = imageSeriesTemplate4.createChild(am4core.Circle)
      circle4.fill = am4core.color('#FF5B5C')
      circle4.stroke = am4core.color('#FF5B5C')
      circle4.fillOpacity = 0.7
      circle4.tooltipText = i18n.locale === 'ru' ? '{cityRu} : {clicks} переходов' : '{cityEng} : {clicks} clicks'

      // Set property fields
      imageSeriesTemplate4.propertyFields.latitude = 'latitude'
      imageSeriesTemplate4.propertyFields.longitude = 'longitude'

      // Add data for the three cities
      imageSeries4.data = this.resultForMapGreaterThan1000
      imageSeries4.dataFields.value = 'clicks'

      // Обьем круга в зависимости от количества кликов
      imageSeriesTemplate4.nonScaling = true
      imageSeries4.heatRules.push({
        target: circle4,
        property: 'radius',
        min: 25,
        max: 30,
        dataField: 'value',
      })

      const imageSeries = []
      imageSeries.push(imageSeries1)
      imageSeries.push(imageSeries2)
      imageSeries.push(imageSeries3)
      imageSeries.push(imageSeries4)
    },
  },
}
</script>
